import React, {Component} from 'react'
import {Progress, Modal, Button,message,Alert,Spin} from 'antd';
import Generator from 'fr-generator';

import {getSurveyPage, submitSurveyPage} from '../../request'
import {CurrBrowser} from '../../public/navigatorInfo'
import moment from 'moment';
import IconFont from "../../public/iconfont/iconFont";

import './oa.less'
const { Provider,  Canvas} = Generator;

const  locationQuery = new URLSearchParams(window.location.search)
const {appVersion,platform,vendor} = navigator
class FormPlayground extends Component {
    genRef = React.createRef()

    state = {
        param:{
            id:this.props.match.params.id!=0?this.props.match.params.id:null,
            externalId:locationQuery.get('externalId')?locationQuery.get('externalId'):null,
            sign:locationQuery.get('sign')?locationQuery.get('sign'):null,
            url:encodeURIComponent(window.location.href)
        },
        formParam:{},
        answerId:null,
        defaultValue:{},
        steps:0,
        percent:0,
        submitParam:{},
        startTime:moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        searchParam:{},
        checkOrNotKey:'',
        submitAble:false,
        requireList:[],
        dialogVisible:false,
        redirectUrl:'',
        loading:true,
        isEnd:null
    }
    initSurveyData = ()=>{
        this.setState({ loading: true });

        getSurveyPage({...this.state.param}).then(res=>{
            let {title,description,attribute,children} = res.survey
            let param = {
                title:title||res.name,
                description,
                suffix:attribute.suffix,
                submitButton:attribute.submitButton
            }
            // let defaultJson = JSON.parse(res.formJson)
            // let defaultJsonObj = {
            //     displayType: "column",
            //     type:'object',
            //     properties:{}
            // }
            let n=1;
            let defaultJsonProperties = {};
            (children||[]).map(item=>{
                console.log(item)
                let jsonItem = JSON.parse(item.formJson||'{}')
                Object.values(jsonItem)
                let setValue = Object.values(jsonItem)[0]
                setValue.order = n++
                if(setValue.hasOwnProperty('required')&&setValue.required){
                    setValue.rules = [
                        {
                            required: true,
                            "message": '该题为必填',
                        },
                    ]
                }

                let setJsonItem = {[Object.keys(jsonItem)]:setValue}
                defaultJsonProperties = {...defaultJsonProperties,...setJsonItem}
            })
            let defaultJson = {
                displayType: "column",
                type:'object',
                properties:defaultJsonProperties
            }
            let answerInit = JSON.parse(res.attachment||'{}')
            let prop = defaultJson.properties
            let index = 1
            let isCheckOrNot = Object.keys(prop)
            let pattern = new RegExp('CheckOrNot')
            let checkOrNotKeys = isCheckOrNot.filter(item=>pattern.test(isCheckOrNot))
            let checkOrNotKey = checkOrNotKeys.length>0?checkOrNotKeys[0]:''
            let requireList = []
            for(let key in prop){
                prop[key].title = `<div style="position: relative;padding-left: 30px;"><span style="position: absolute;left: 0;top: 0">${index++}、 </span>${prop[key].title}</div>`
                let initId = key.split('_')[1]
                for(let key1 in answerInit){
                    if(initId==key1){
                        prop[key].default = answerInit[key1]
                    }
                }
                if(locationQuery.get(initId)){
                    prop[key].default = locationQuery.get(initId)
                }
                if(checkOrNotKey&&key.indexOf('CheckOrNot')<0){
                    prop[key].hidden = '{{!rootValue.'+checkOrNotKey+'}}'
                }
                if(prop[key].required){
                    requireList.push(key)
                }

            }
            console.log('formJson2',defaultJson)
            this.setState({
                isEnd:res.setting?res.setting.answerSetting.isEnd:null,
                loading:false,
                formParam:param,
                defaultValue: {...defaultJson},
                steps:res.survey.children.length,
                answerId:res.answerId,
                checkOrNotKey,
                requireList,
                redirectUrl:res.setting?res.setting.submittedSetting.redirectUrl:''
            })
        })
    }
    setInitAnswer = (answerInfo)=>{
        console.log('set',answerInfo)
        for(let key in answerInfo){

        }
    }
    handlePageChange = (data)=>{
        console.log('data',data)
        let param = this.state.submitParam
        param.answerOrigin = {...data}
        this.setState({
            submitParam:param
        })
        console.log(this.state.submitParam)
        this.setProgress()
    }
    setIdType = (idType)=>{
        let setArr = idType.split('_')
        let type = setArr[0]
        setArr.splice(0,1)
        return {id:setArr.join('_'),type:type}
    }
    handleSubmit = ()=>{
        console.log(123)
        let param = this.state.submitParam
        let answerOri = param.answerOrigin
        let setAnswer = {}
        let requireList = this.state.requireList
        console.log(requireList,param,answerOri)
        for(let key in answerOri){
            let setId = this.setIdType(key).id
            setAnswer[setId] = answerOri[key]
            if(requireList.indexOf(key)>-1&&(answerOri[key]==undefined||answerOri[key]=='')){
                message.error('带*的都为必填')
                return false
            }
        }
        param.answer = setAnswer
        param.projectId = this.state.param.id
        param.id = this.state.answerId||undefined
        param.url = encodeURIComponent(window.location.href)
        param.metaInfo = {
            answerInfo:{
                startTime:this.state.startTime,
                endTime:moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
            },
            clientInfo:{
                browser:CurrBrowser.getPC().broswer,
                browserVersion:CurrBrowser.getPC().version,
                deviceType:CurrBrowser.getDeviceType(),
                platform,
                platformVersion:''
            }
        }
        console.log(56,param)
        let {answerOrigin,...otherParam} = param
        submitSurveyPage({...otherParam}).then(res=>{
            this.setState({
                dialogVisible:true
            })
            // Modal.success({
            //     title:'提示',
            //     content:'您已经成功提交问卷！',
            //     maskClosable:false,
            //     closable:false,
            //     onCancel:()=>{
            //         console.log('c')
            //     },
            //     onOk:()=>{
            //         console.log('OK')
            //     },
            //     onClose
            // })
        })
    }
    setProgress = ()=>{
        let answer = this.state.submitParam.answerOrigin
        let steps = this.state.steps
        let ansNum = 0
        for(let key in answer){
            if(answer[key]!==undefined&&answer[key]!==''){
                ansNum++
            }
        }
        console.log(ansNum)
        this.setState({
            percent:ansNum*100/steps,
            submitAble:ansNum>0?true:false
        })
    }
    toPage = ()=>{
        let url = this.state.redirectUrl
        if(url){
            window.location.href = url
        }
    }
    handleCloseModal = ()=>{
        this.setState({
            dialogVisible:false
        })
        if(this.state.param.id){
            this.initSurveyData()
        }
    }
    componentDidMount(){
        console.log('location',locationQuery,this.props.location.search)
        if(this.state.param.id){
            this.initSurveyData()
        }
    }
    render() {
        return (
            <div className={'form-playground'}>
                <Spin spinning={this.state.loading}>
                    <div className={'form-playground-wrap'}>
                        {
                            this.state.isEnd?
                                <div style={{position:"absolute",top:'0',left:'0',right:'0',bottom:'0',zIndex:99999,background:'rgba(255,255,255,.8)'}}>
                                    <IconFont style={{position:'absolute',top:'10px',left:'10px',fontSize:'150px',color:'green'}} type={'icon-yijieshu6'}/>
                                </div>:''
                        }
                        <span className={'icon'}></span>
                        <div>
                            <Progress strokeLinecap={'square'} percent={this.state.percent} status="exception" showInfo={false} />
                        </div>
                        <div className={'form-playground-content survey-render-wrap'}>
                            <div style={{padding:'10px 0'}}>
                                <div className={'survey-header-title'}>
                                    <span>{locationQuery.get('userName')?decodeURIComponent(locationQuery.get('userName'))+' - ':''}</span>
                                    {this.state.formParam.title}
                                </div>
                                <div className={'survey-header-des'}>{this.state.formParam.description}</div>
                            </div>
                            <Provider
                                readOnly
                                ref={this.genRef}
                                validation={true}
                                preview={true}
                                extraButtons={[false,false,false,false]}
                                defaultValue={this.state.defaultValue}
                                onChange={(data)=>this.handlePageChange(data)}
                                onSchemaChange={schema => console.log('schema:change', schema)}
                            >
                                <div >
                                    <Canvas />
                                </div>
                            </Provider>
                            <div style={{padding:'10px 0'}}>
                                <div className={'survey-footer-des'}>{this.state.formParam.suffix}</div>
                                {
                                    !this.state.isEnd?
                                        <Button disabled={!this.state.submitAble} type={'primary'} className={'survey-footer-btn'} onClick={this.handleSubmit}>{this.state.formParam.submitButton}</Button>
                                        :''
                                }
                            </div>
                        </div>
                        <Modal open={this.state.dialogVisible} footer={
                            this.state.redirectUrl?
                                <Button type={'primary'} onClick={
                                    this.toPage
                                }>知道啦</Button>
                                :''
                        } onCancel={this.handleCloseModal}>
                            {/*<Icon.CheckCircleOutlined />*/}
                            {/*您已经成功提交问卷！*/}
                            <Alert
                                message="提示"
                                description="您已经成功提交问卷！"
                                type="success"
                                showIcon
                            />
                        </Modal>
                    </div>
                </Spin>

            </div>

        )
    }
}

export default FormPlayground